/* Add some simple styling to structure the UI */
body {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* padding: 20px; */
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .event-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .event-form {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background-color: #f7f7f7;
    border-top: 1px solid #ccc;
  }
  

.event-form input {
  padding: 10px;
}

.event-bt {
  margin-right: 40px !important;
}

.event-list ul {
  list-style: none;
  padding: 0;
}

.event-list li {
  margin: 10px 0;
}

.ticket-details, .event-page {
  margin-top: 20px;
}

button {
  margin: unset !important;
}

input[type="text"], input[type="search"] {
  width: 200px;
  padding: 10px;
}

h2 {
  color: #333;
}

.event-page {
  position: relative;
  padding-top: 20px; /* To give space above the search input */
  min-height: 100vh; /* Ensure page is tall enough to scroll */
  padding-bottom: 100px; /* Add padding at the bottom to avoid overlaps */
}

ul {
  list-style: none;
  padding: 0;
  margin-top: 20px; /* Space between search and ticket list */
}

li {
  background: white;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}


.floating-button i {
  font-size: 24px;
}

.search-wrapper {
  display: flex;
  align-items: center; /* Vertically align the items in the center */
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it stays on top of other content */
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  justify-content: space-between; /* Ensures space between search and sync button */
}

.left {
  display: flex;
  align-items: center;
}

.search-input-container {
  flex-grow: 1;
  margin-left: 10px; /* Space between back button and search */
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.right {
  display: flex;
  align-items: center;
  padding-left: 20px;
}


.floating-button {
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  background-color: #1e2b47;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 56px !important;
  height: 56px !important;
  z-index: 1000; /* Ensure it's above other elements */
}

.floating-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
}

.badge-success {
  background-color: green;
  color: white;
  padding: 5px;
}

.badge-primary {
  background-color: blue;
  color: white;
  padding: 5px;
}

.badge-tertiary {
  background-color: orange;
  color: white;
  padding: 5px;
}

.badge-warning {
  background-color: yellow;
  color: black;
  padding: 5px;
}

.ticket-details-page {
  padding: 20px;
}

.btn {
  margin: 10px 0;
  padding: 10px;
}

.btn-success {
  background-color: green;
  color: white;
}

.btn-secondary {
  background-color: grey;
  color: white;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the video fills the area */
}
